import axios from "axios";

const dataProvider = (apiUrl) => {
  // we need this pre-flight function, because of lifecycle issues
  const recreateAxios = () => {
    const auth = JSON.parse(localStorage.getItem("auth"));
    let token;
    let headers = {};
    if (auth && auth.accessToken) {
      token = auth.accessToken;
      headers = {
        Authorization: "Bearer " + token,
        Accept: "*/*",
      };
    }
    //console.log("our headers are")
    //console.log(headers)

    const ax = axios.create({
      baseURL: apiUrl,
      headers: headers,
    });
    return ax;
  };

  return {
    getList: (resource) => {
      let route = "";
      let qparams = {};

      switch (resource) {
        case "devices":
          var data = {
            descending: false,
            pageInfo: {
              pageSize: 400, //
              pageNum: 0,
            },
          };
          route = "device/list";
          var defaultDomain = localStorage.getItem("domain");
          qparams = {
            domainUuid: defaultDomain, // hard-coded for now
          };
         // console.log(qparams.domainUuid);
         // console.log(localStorage.getItem("domain"));

          break;
        case "domains":
          data = {
            descending: false,
            pageInfo: {
              pageSize: 400, //
              pageNum: 0,
            },
          };
          route = "domain/list";
          break;

        case "users":
          data = {
            descending: false,
            pageInfo: {
              pageSize: 400, //
              pageNum: 0,
            },
          };
          route = "user/list";
          break;
        default:
          route = "";
      }

      let url = `${apiUrl}/${route}`;

      //console.log("query", url, data, qparams);
      const ax = recreateAxios();
      return ax({
        method: "POST",
        url: url,
        data: data,
        params: qparams,
      })
        .then((res) => {
          let result = [];
          result = res.data.data.map((e) => {
            e.id = e.uuid;
            return e;
          });
          //const result = res.data
          const endresult = { data: result, total: result.length };
         // console.log(endresult);
          return Promise.resolve(endresult);
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getOne: (resource, params) => {
      /*if(resource === 'domains'){
        return Promise.resolve({ data:
        {
          id: '28d1e52b-36bb-4bd4-86fe-b3058c9dc5d1',
          uuid: '28d1e52b-36bb-4bd4-86fe-b3058c9dc5d1',
          name: 'asd',
          info: 'asdasd'
        }
      })
      }*/

      /*if(resource === 'devices'){
        const fakedev = {
          "id": 1,
          "uuid": "d3c44ecb-8dbf-421c-8b03-1d374051f5cb",
          "name": "testDevice1",
          "status": {
              "batteryInfo": {
                  "unit": "%",
                  "value": 32
              },
              "networkInfo": {
                  "connectionType": "(unknown)",
                  "ip4": "",
                  "ip6": "",
                  "mac": "",
                  "signalStrength": {
                      "unit": "%",
                      "value": 5
                  },
                  "imei": ""
              },
              "gpsLocation": {
                  "latitude": 47,
                  "longitude": 18,
                  "altitude": 123
              }
          },
          "imei": "",
          "physicalId": "testDevicePhysId1",
          "customData": "{}",
          "customStatus": "{}",
          "domainUuid": "28d1e52b-36bb-4bd4-86fe-b3058c9dc5d1",
          "config": "",
          "desiredConfig": "",
          "deviceGroupUuids": {
              "data": []
          }
        }
        return Promise.resolve({
          data: fakedev
        })
      }*/

      let route = "";
      switch (resource) {
        case "devices":
          route = "device/get_by_uuid";
          break;
        case "domains":
          route = "domain/get_by_uuid";
          break;
        default:
          route = "";
      }

      let url = `${apiUrl}/${route}`;

      //console.log("getOne", url, params);
      const ax = recreateAxios();
      return ax({
        method: "GET",
        url: url,
        params: {
          uuid: params.uuid,
        },
      })
        .then((res) => {
          res.data.id = res.data.uuid;
          //console.log(res.data)
          return Promise.resolve({ data: res.data });
        })
        .catch((err) => {
          console.log(err);
        });
    },

    getMany: () => {
      //console.log("getMany fired");
    },

    getStatusList: (
      resource,
      deviceuuid,
      pageSize,
      pagereq,
      fromDate,
      toDate,
    ) => {
      let route = "";
      switch (resource) {
        case "devices":
          route = "device/status/list";
          break;
        default:
          route = "";
      }

      let url = `${apiUrl}/${route}`;
      console.log('getStatusList', url,deviceuuid,pageSize,pagereq)
      let data = {
        descending: true,
        pageInfo: {
          pageSize: pageSize,
          pageNum: pagereq,
        },
      };
      const ax = recreateAxios();
      return ax({
        method: "POST",
        url: url,
        data: data,
        params: {
          deviceuuid: deviceuuid,
          from: fromDate,
          to: toDate,
        },
      })
        .then((res) => {
          res.data.id = res.data.uuid;
          //console.log(res.data.data)
          return Promise.resolve({ data: res.data.data });
        })
        .catch((err) => {
          console.log(err);
        });
    },

    getStatusListCount: (resource, params,fromDate,toDate) => {
      let route = "";
      switch (resource) {
        case "devices":
          route = "device/status/list/count";
          var url = `${apiUrl}/${route}`;

          //console.log("getStatusListCount", url, params);
          var data = {
            "descending": true,
            "pageInfo": {
              "pageSize": 9999999999999,
              "pageNum": 0
          }
          }
          var ax = recreateAxios()
          return ax({
            method: "POST",
            url: url,
            data: data,
            params: {
              deviceuuid: params.id,
              from:fromDate,
              to:toDate,
            },
          })
            .then((res) => {
              res.data.id = res.data.uuid;
              //console.log(res.data.resultCount)
              return Promise.resolve({ data: res.data.resultCount });
            })
            .catch((err) => {
              console.log(err);
            });

        case "domains":
          route = "domain/list/count";
          url = `${apiUrl}/${route}`;

         // console.log("getStatusListCount", url, params);
          data = {
            descending: false,
          };
          ax = recreateAxios();
          return ax({
            method: "POST",
            url: url,
            data: data,
            params: {},
          })
            .then((res) => {
              //console.log(res.data.resultCount);
              return Promise.resolve({ data: res.data.resultCount });
            })
            .catch((err) => {
              console.log(err);
            });
        default:
          route = "";
      }
    },
    getManyReference: () => {
      //console.log("getManyRef fired");
    },
    create: (resource, params) => {
      return new Promise((resolve, reject) => {
        const ax = recreateAxios();
        switch (resource) {
          case "devices":
           // console.log(params.data);
            var newDevice = {
              ...params.data,
              customData: "{}",
              customStatus: "{}",
              eventEnabled: true,
            };

            ax({
              method: "POST",
              url: "/device/create",
              data: newDevice,
            })
              .then((res) => {
                resolve({
                  data: {
                    ...newDevice,
                    id: res.data.uuid,
                    uuid: res.data.uuid,
                  },
                });
              })
              .catch((err) => reject(err));
            break;
          case "domains":
            var newDomain = {
              ...params.data,
              customData: "{}",
              customStatus: "{}",
              eventEnabled: true,
            };

            ax({
              method: "POST",
              url: "/domain/create",
              data: newDomain,
            })
              .then((res) => {
                resolve({
                  data: {
                    ...newDomain,
                    id: res.data.uuid,
                    uuid: res.data.uuid,
                  },
                });
              })
              .catch((err) => reject(err));
            break;

          case "users":
            //console.log(params.data);
            var newUser = {
              ...params.data,
              customData: "{}",
              customStatus: "{}",
              eventEnabled: true,
            };

            var data = JSON.stringify({
              username: params.data.name,
              password: params.data.password,
              info: params.data.info,
            });

            ax({
              method: "POST",
              url: "/user/create",
              data: data,
            })
              .then((res) => {
              //  console.log(res);
                resolve({
                  ...newUser,
                  id: res.data.uuid,
                  uuid: res.data.uuid,
                });
              })
              .catch((err) => reject(err));
            break;
          default:
        }
      });
    },
    update: (resource, params) => {
     // console.log(params);
      return new Promise((resolve,reject) => {
        const ax = recreateAxios();
        switch (resource) {
          case "domains":
            var data = JSON.stringify({
              uuid: params.data.uuid,
              name: params.data.name,
              info: params.data.info,
            });

            ax({
              method: "patch",
              url: `${apiUrl}/domain/modify`,

              data: data,
            }).then((res) => {
              resolve({
                data: {
                  id: res.data.uuid,
                  uuid: res.data.uuid,
                },
              });
            }).catch((err) => reject(err));
            break;
          case "devices":
            data = JSON.stringify({
              uuid: params.data.uuid,
              name: params.data.name,
              physicalId: params.data.physicalId,
              domainUuid: params.data.domainUuid,
            });

            ax({
              method: "patch",
              url: `${apiUrl}/device/modify`,

              data: data,
            }).then((res) => {
              resolve({
                data: {
                  id: res.data.uuid,
                  uuid: res.data.uuid,
                },
              });
            }).catch((err) => reject(err));
            break;
          //Ez még nem megy
          /*case 'users':
            var data = JSON.stringify({uuid: params.data.uuid, username: params.data.name, email: params.data.email,
                                       firstName: params.data.firstName,lastName: params.data.lastName,title: params.data.title,
                                       info: params.data.info});

            ax({
              method: 'patch',
              url: ':)',

              data : data
            }).then(res => {
              resolve({
                data: {
                  id: res.data.uuid,
                  uuid: res.data.uuid
                }
              })
            })
          break;*/
        }
      });
    },
    updateMany: () => {},

    delete: (resource, params) => {
     // console.log(params);
      return new Promise((resolve, reject) => {
        const ax = recreateAxios();
        switch (resource) {
          case "devices":
            var data = { data: [params.id] };
            ax({
              method: "POST",
              url: "/device/delete",
              data: data,
              params: { "soft": false }
            })
              .then(() => {
                resolve({ data: { id: params.id } });
              })
              .catch((err) => reject(err));
            break;

          case "users":
            data = { data: [params.id] };
            /*if(params.previousData.username === 'admin'){
              return Promise.reject()
            }*/
            ax({
              method: "POST",
              url: "/user/delete",
              data: data,
            })
              .then(() => {
                resolve({ id: params.id });
              })
              .catch((err) => reject(err));
            break;

          case "domains":
            data = {
              descending: false,
              pageInfo: {
                pageSize: 400, //
                pageNum: 0,
              },
            };
            var Params = {
              domainUuid: params.id,
            };

            ax({
              method: "POST",
              url: "/role/list",
              data: data,
              params: Params,
            })
              .then((res) => {
               // console.log(res.data.data);
                var rolearray = res.data.data;
                var role = {
                  data: [],
                };

                rolearray.map((data) => {
                  role.data.push(data.uuid);
                });
               // console.log(role);

                ax({
                  method: "POST",
                  url: "/role/delete",
                  data: role,
                })
                  .then(() => {
                    data = { data: [params.id] };
                    ax({
                      method: "POST",
                      url: "/domain/delete",
                      data: data,
                    })
                      .then(() => {
                        resolve({ data: { id: params.id } });
                      })
                      .catch((err) => reject(err));
                  })
                  .catch((err) => reject(err));
              })
              .catch((err) => reject(err));
            break;
          default:
        }
      });
    },
    deleteMany: () => {},
  };
};
export default dataProvider;
